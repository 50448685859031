import { AngledHighwayShape } from '@/modules/angledHighways/types';
import { Crossing } from '@/modules/common/types/connections';
import { DTShape, HighwayShape } from '@/store/recoil/shape';
import { Vector2 } from 'three';
import { decodeShapeId, encodeIdWithVehicleId } from '../idEncoder';
import { VehicleSpec } from '../types';
import { addCrossingCpCutout } from './addCrossingCpCutout';
import { addCrossingGateMapping } from './addCrossingGateMapping';
import { isAngledHighwayShape, isHighwayShape } from '@/modules/common/types/guards';

const POINT_ON_LINE_THRESHOLD = 10;

type CrossingSegment = {
  fromHighwayId: string;
  fromSegmentId?: number;
  toHighwayId: string;
  toSegmentId?: number;
};

export type CheckPointAtCrossing = {
  fromCheckPointId: string;
  toCheckPointIds: string[];
};

export const mapHighwayCrossings = (
  vehicleSpec: VehicleSpec,
  highwayId: string,
  crossings: Crossing[],
  shapes: DTShape[],
) => {
  const vehicleDatabaseId = vehicleSpec.databaseId;
  const highway = shapes.find((shape) => decodeShapeId(highwayId).split('_')[0] === decodeShapeId(shape.id));
  if (
    !isHighwayShape(highway) && !isAngledHighwayShape(highway)
  ) return;

  const crossingSegments = crossings.map(
    (crossing) => { 
      const targetHighway = shapes.find((shape) => decodeShapeId(shape.id) === decodeShapeId(crossing.to));
      if (
        !isHighwayShape(targetHighway) && !isAngledHighwayShape(targetHighway) 
      ) return;

      return getCrossingSegment(crossing, highway, targetHighway) 
    })

    const checkPointAtCrossings = convertCrossingSegmentsToCheckpointCrossing(
      crossingSegments,
      vehicleDatabaseId,
    );
    addCrossingGateMapping(vehicleSpec, checkPointAtCrossings);
    addCrossingCpCutout(vehicleSpec, checkPointAtCrossings);
};

const convertCrossingSegmentsToCheckpointCrossing = (
  crossingSegments: CrossingSegment[],
  vehicleDatabaseId: number,
): CheckPointAtCrossing[] => {
  const cps: Map<string, string[]> = new Map();
  crossingSegments.forEach((crossing) => {
    const fromCpId = encodeIdWithVehicleId(
      crossing.fromHighwayId,
      vehicleDatabaseId,
      crossing.fromSegmentId,
    );

    if (!cps.has(fromCpId)) {
      cps.set(fromCpId, []);
    }
    const toCpIds = cps.get(fromCpId);
    const toCpId = encodeIdWithVehicleId(
      crossing.toHighwayId,
      vehicleDatabaseId,
      crossing.toSegmentId,
    );
    toCpIds.push(toCpId);
  });
  const checkPointAtCrossings: CheckPointAtCrossing[] = [];
  cps.forEach((toCheckPointIds, fromCheckPointId) => {
    checkPointAtCrossings.push({
      fromCheckPointId,
      toCheckPointIds,
    });
  });
  return checkPointAtCrossings;
};

const getCrossingSegment = (
  crossing: Crossing,
  highwayA: HighwayShape | AngledHighwayShape,
  highwayB: HighwayShape | AngledHighwayShape,
) => {
  const fromHighwayId = crossing.from;
  let fromSegmentId: number | undefined;
  if (isAngledHighwayShape(highwayA)) {
    fromSegmentId = findClosestSegment(highwayA, crossing.position);
    if (fromSegmentId === null) return;
  }
  const toHighwayId = crossing.to;
  let toSegmentId: number | undefined;
  if (isAngledHighwayShape(highwayB)) {
    toSegmentId = findClosestSegment(highwayB, crossing.position);
    if (toSegmentId === null) return;
  }

  return {
    fromHighwayId,
    fromSegmentId,
    toHighwayId,
    toSegmentId,
  };
};

const findClosestSegment = (highway: AngledHighwayShape, point: Vector2): number | null => {
  const controlPoints = highway?.properties?.controlPoints;
  if (!controlPoints) return null;
  for (let i = 0; i < controlPoints.length - 1; ++i) {
    const p0 = controlPoints[i].position.clone();
    const p1 = controlPoints[i + 1].position.clone();
    const p1_p0 = p1.clone().sub(p0);
    const v_p0 = point.clone().sub(p0);
    const p1_v = p1.clone().sub(point);

    // point within p1_p0
    // p0 --- v --- p1
    const delta = Math.abs(p1_p0.length() - (v_p0.length() + p1_v.length()));
    if (delta < POINT_ON_LINE_THRESHOLD) return i;

    // point is outside p1_p0 but on the same line
    // p0 --- p1 --- v
    const delta2 = Math.abs(v_p0.length() - (p1_p0.length() + p1_v.length()));
    if (delta2 < POINT_ON_LINE_THRESHOLD) return i;
  }
  return null;
};
