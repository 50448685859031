import { Reflector } from '@/modules/commissioning/';
import { getMiddlePoint } from '@/modules/common/helpers/math';
import {
  doPolygonsIntersect,
  lineToPolygon,
  makeOffsetPoly,
} from '@/modules/common/helpers/polygon';
import {
  AreaAlignment,
  AreaDirection,
  AreaEndpointDirection,
  AreaLoadCarrierOrientation,
  AreaLoadPlacement,
  HighwayDirection,
  LaneDirection,
  ShapeProperties,
  ShapeType,
} from '@/modules/common/types/shapes';
import { StorageType } from '@/modules/common/types/storage';
import { BoundingBox, Rectangle } from '@helpers/types';
import { getSegments } from '@modules/angledHighways/helpers';
import { WideLineSegment, LineSegment } from '@/modules/common/types/general';
import { DEFAULT_LOAD_BOUNDING_BOX_DIMENSIONS } from '@modules/common/constants/storage';
import { allEqual } from '@modules/common/helpers/array';
import { flipBoundingBoxVertically } from '@modules/common/helpers/boundingBox';
import { numberOfRows } from '@modules/common/helpers/loadCarrier';
import { AngledHighwayShapePersisted, LoadCarrierType } from '@modules/common/types/floorPlan';
import { connectionPointBetweenPolygons, midpoint } from '@modules/connections/common/helpers';
import {
  angleBetweenVectors,
  areLinesParallel,
  centerPoint,
  isVertical,
} from '@modules/workspace/helpers/shape';
import { Vector2, Vector3 } from 'three';
import { RAD2DEG } from 'three/src/math/MathUtils';
import {
  Alignment,
  AreaType,
  ConnectionDirection,
  FPBoundingBox,
  FpsReflectorFlat,
  LoadHandlingType,
  LoadTypeReferences,
  StationaryTypeEnum,
  TwinConfiguration,
} from './types';
import { Crossing } from '@/modules/common/types/connections';

const POLYGON_OUTSET_SIZE = -50; // minus to make object bigger
const NEXT_OR_AFTER_CUTOFF = 1.0;

const mapToFPOrigin = (
  boundingBox: BoundingBox,
  workspaceBoundingBox: BoundingBox,
): BoundingBox => {
  const { x, y, height, width } = boundingBox;

  return {
    x: x - workspaceBoundingBox.x,
    y: y - workspaceBoundingBox.y,
    width,
    height,
  };
};

const mapToIntegerValues = (boundingBox: BoundingBox) => {
  const { x, y, height, width } = boundingBox;

  return {
    x: Math.floor(x),
    y: Math.floor(y),
    width: Math.floor(width),
    height: Math.floor(height),
  };
};

export const boundingBoxToFpsBoundingBox = (
  boundingBox: BoundingBox,
  workspaceBoundingBox: BoundingBox,
): FPBoundingBox => {
  let bbBox = mapToIntegerValues(boundingBox);
  bbBox = mapToFPOrigin(bbBox, workspaceBoundingBox);
  bbBox = flipBoundingBoxVertically(bbBox, workspaceBoundingBox);

  return {
    width: bbBox.width,
    length: bbBox.height,
    height: null,
    minX: bbBox.x,
    minY: bbBox.y,
    minZ: null,
  };
};

export const lineToFpsRectangle = (
  line: WideLineSegment,
  workspaceBoundingBox: BoundingBox,
): Rectangle => {
  const pointA: Vector2 = new Vector2(
    line.points.start.x - workspaceBoundingBox.x,
    workspaceBoundingBox.height - (line.points.start.y - workspaceBoundingBox.y),
  );
  const pointB: Vector2 = new Vector2(
    line.points.end.x - workspaceBoundingBox.x,
    workspaceBoundingBox.height - (line.points.end.y - workspaceBoundingBox.y),
  );

  const middlePoint = getMiddlePoint(pointA, pointB);

  const vector = new Vector2().subVectors(pointB, pointA);
  let angle = new Vector3(vector.x, vector.y, 0).angleTo(new Vector3(1, 0, 0)) * RAD2DEG;
  if (pointA.y > pointB.y) angle = 360 - angle;

  const distanceBetweenPoints = vector.length();

  return {
    centerX: middlePoint.x,
    centerY: middlePoint.y,
    width: Math.min(distanceBetweenPoints, line.width),
    length: Math.max(distanceBetweenPoints, line.width),
    angleInDegrees: Math.round(angle),
  };
};

export const boundingBoxToFpsRectangle = (
  boundingBox: BoundingBox,
  angle: number,
  workspaceBoundingBox: BoundingBox,
): Rectangle => {
  let center = centerPoint(boundingBox, angle);
  center = new Vector3(
    center.x - workspaceBoundingBox.x,
    workspaceBoundingBox.height - (center.y - workspaceBoundingBox.y),
    0,
  );
  angle %= 360;
  if (boundingBox.height > boundingBox.width) {
    angle += 90;
  }
  if (angle < 0) angle += 360;
  if (angle === 360) angle = 0;

  return {
    centerX: center.x,
    centerY: center.y,
    width: Math.min(boundingBox.width, boundingBox.height),
    length: Math.max(boundingBox.width, boundingBox.height),
    angleInDegrees: Math.round(angle),
  };
};

export const areaTypeToFPAreaType = (type: string): AreaType => {
  const typeMap = {
    [ShapeType.STORAGE]: AreaType.STORAGE,
    [ShapeType.STORAGE_POSITION]: AreaType.STORAGE,
    [ShapeType.CHARGING]: AreaType.STATIONARY,
    [ShapeType.CHARGING_POSITION]: AreaType.STATIONARY,
    [ShapeType.INTAKE]: AreaType.INTAKE,
    [ShapeType.INTAKE_POSITION]: AreaType.INTAKE,
    [ShapeType.DELIVERY]: AreaType.DELIVERY,
    [ShapeType.DELIVERY_POSITION]: AreaType.DELIVERY,
    [ShapeType.PROCESS_ONE_EP]: AreaType.PROCESS,
    [ShapeType.PROCESS_ONE_EP_POSITION]: AreaType.PROCESS,
    [ShapeType.PARKING]: AreaType.STATIONARY,
    [ShapeType.PARKING_POSITION]: AreaType.STATIONARY,
    [ShapeType.HIGHWAY]: AreaType.HIGHWAY,
    [ShapeType.HIGHWAY_ANGLED]: AreaType.HIGHWAY,
  };

  return typeMap[type] ?? AreaType.EMPTY;
};

export const alignmentAdjustment = (width, height, direction, alignment) => {
  const isHorizontal = width >= height;

  if (isHorizontal && (direction === AreaDirection.LEFT || direction === AreaDirection.RIGHT)) {
    if (alignment === 'bottom') {
      return 'top';
    }
    if (alignment === 'top') {
      return 'bottom';
    }
  }

  return alignment;
};

export const alignmentToFPAlignment = (alignment: string) => {
  const typeMap = {
    [AreaAlignment.TOP]: Alignment.TOP,
    [AreaAlignment.CENTER]: Alignment.CENTER,
    [AreaAlignment.BOTTOM]: Alignment.BOTTOM,
  };

  return typeMap[alignment];
};

export const fpsEndPointAngle = (direction: AreaDirection, areaIsHorizontal: boolean) => {
  if (areaIsHorizontal) {
    // eslint-disable-next-line default-case
    switch (direction) {
      case AreaDirection.RIGHT:
        return 0;
      case AreaDirection.UP:
        return 90;
      case AreaDirection.LEFT:
        return 180;
      case AreaDirection.DOWN:
        return 270;
    }
  }

  // eslint-disable-next-line default-case
  switch (direction) {
    case AreaDirection.RIGHT:
      return 270;
    case AreaDirection.UP:
      return 0;
    case AreaDirection.LEFT:
      return 90;
    case AreaDirection.DOWN:
      return 180;
  }
};

export const findConnections = (id: string, connections: any[]) =>
  connections
    .filter(
      (connection) =>
        (connection.outChecked || connection.inChecked) &&
        [connection.from, connection.to].includes(id),
    )
    .map((connection) => {
      const { inChecked, outChecked, from, to, rot, usePivots } = connection;

      if (from === id) {
        return {
          targetName: to.split(':')[0],
          direction:
            inChecked && outChecked
              ? ConnectionDirection.BothWays
              : inChecked
              ? ConnectionDirection.FromTarget
              : ConnectionDirection.FromSource,
          rot,
          usePivots,
        };
      }

      return {
        targetName: from.split(':')[0],
        direction:
          inChecked && outChecked
            ? ConnectionDirection.BothWays
            : inChecked
            ? ConnectionDirection.FromSource
            : ConnectionDirection.FromTarget,
        rot,
        usePivots,
      };
    });

// to check if this needs refactoring out harcoded values based on single load dimensions
const storageLoadSize = (shapeSize, vehicleLength, loadLength) => {
  const lengthForLoad = shapeSize - vehicleLength + loadLength;
  const numberOfLoads = numberOfRows(lengthForLoad, loadLength, 50);
  return numberOfLoads * loadLength + (numberOfLoads - 1) * 50;
};

export const storageLoadWidth = (
  shapeWidth: number,
  direction: string,
  vehicleLength: number,
  loadCarriersBoundingBox,
) => {
  if (direction === AreaDirection.DOWN || direction === AreaDirection.UP) {
    return loadCarriersBoundingBox.width;
  }
  return storageLoadSize(shapeWidth, vehicleLength, loadCarriersBoundingBox.length);
};

export const storageLoadLength = (
  shapeHeight: number,
  direction: string,
  vehicleLength: number,
  loadCarriersBoundingBox,
) => {
  if (direction === AreaDirection.DOWN || direction === AreaDirection.UP) {
    return storageLoadSize(shapeHeight, vehicleLength, loadCarriersBoundingBox.width);
  }
  return loadCarriersBoundingBox.length;
};

export const blockStorageLength = (
  properties: ShapeProperties,
  direction: AreaDirection,
  loadCarrierOrientation: AreaLoadCarrierOrientation,
  vehicleLength: number,
  loadWidth: number,
  loadLength: number,
) => {
  const loadShortSide = Math.min(loadWidth, loadLength);
  const loadLongSide = Math.max(loadWidth, loadLength);
  const shapeSize = isVertical(direction) ? properties.height : properties.width;
  const loadSize =
    loadCarrierOrientation === AreaLoadCarrierOrientation.SHORT_SIDE ? loadShortSide : loadLongSide;
  return shapeSize - vehicleLength + loadSize;
};

export const blockStorageWidth = (
  carrierSide: AreaLoadCarrierOrientation,
  loadWidth,
  loadLength,
) => {
  const loadShortSide = Math.min(loadWidth, loadLength);
  const loadLongSide = Math.max(loadWidth, loadLength);
  return carrierSide === AreaLoadCarrierOrientation.SHORT_SIDE ? loadShortSide : loadLongSide;
};

export const findCrossings = (id: string, crossings: Crossing[]) =>
  crossings
    .filter((crossing) => [crossing.from, crossing.to].includes(id))
    .map((crossing) => {
      const { from, to } = crossing;

      if (from === id) {
        return {
          ...crossing,
        };
      }

      return {
        ...crossing,
        from: to,
        to: from,
      };
    });

export const highwayLaneDirectionToTwinConfig = (
  laneDirection: string,
  width: number,
  height: number,
  angle: number,
) => {
  const upDown = height >= width;
  const flipped = angle >= 135 && angle < 315;
  const map = {
    [LaneDirection.LEFT_RIGHT]: TwinConfiguration.TWO_WAY,
    [LaneDirection.LEFT]:
      upDown || flipped
        ? TwinConfiguration.ONE_WAY_KEEP_FIRST
        : TwinConfiguration.ONE_WAY_KEEP_SECOND,
    [LaneDirection.RIGHT]:
      upDown || flipped
        ? TwinConfiguration.ONE_WAY_KEEP_SECOND
        : TwinConfiguration.ONE_WAY_KEEP_FIRST,
  };

  return map[laneDirection];
};

export const angledHighwayLaneDirection = (laneDirection: LaneDirection, angle: number) => {
  if (angle < 315 && angle > 135) {
    if (laneDirection === LaneDirection.LEFT) return LaneDirection.RIGHT;
    if (laneDirection === LaneDirection.RIGHT) return LaneDirection.LEFT;
  }

  return laneDirection;
};

export const subAreaProperties = (
  properties: any,
  direction: string,
  i: number,
  subAreaSize: number,
  offset: number,
) => {
  if (direction === 'up') {
    return {
      ...properties,
      y: properties.y + i * subAreaSize + offset,
      height: subAreaSize,
    };
  }
  if (direction === 'down') {
    return {
      ...properties,
      y: properties.y + properties.height - subAreaSize - i * subAreaSize - offset,
      height: subAreaSize,
    };
  }
  if (direction === 'left') {
    return {
      ...properties,
      x: properties.x + i * subAreaSize + offset,
      width: subAreaSize,
    };
  }
  if (direction === 'right') {
    return {
      ...properties,
      x: properties.x + properties.width - subAreaSize - i * subAreaSize - offset,
      width: subAreaSize,
    };
  }
};

export const subHighwayProperties = (
  properties: any,
  direction: string,
  i: number,
  subAreaSize: number,
) => {
  if (direction === 'leftright') {
    return {
      ...properties,
      y: properties.y + i * subAreaSize,
      height: subAreaSize,
    };
  }
  if (direction === 'updown') {
    return {
      ...properties,
      x: properties.x + properties.width - subAreaSize - i * subAreaSize,
      width: subAreaSize,
    };
  }
};

export const areaTypeToEndPointGenerationSettingsType = (
  type: string,
  loadPlacement?: AreaLoadPlacement,
): string => {
  const typeMap = {
    [ShapeType.STORAGE]: LoadHandlingType.STORAGE,
    [ShapeType.CHARGING]: StationaryTypeEnum.CHARGING,
    [ShapeType.INTAKE]: LoadHandlingType.INTAKE,
    [ShapeType.DELIVERY]: LoadHandlingType.DELIVERY,
    [ShapeType.PROCESS_ONE_EP]:
      loadPlacement === AreaLoadPlacement.OFF
        ? LoadHandlingType.PROCESS
        : LoadHandlingType.PROCESS_ON_VEHICLE,
    [ShapeType.HANDOVER]: LoadHandlingType.PROCESS,
    [ShapeType.PARKING]: StationaryTypeEnum.PARKING,
  };

  return typeMap[type] ?? '';
};

export const positionTypeToFixedEndPointGenerationType = (type: string): string => {
  const positionTypeMap = {
    [ShapeType.INTAKE_POSITION]: LoadHandlingType.INTAKE,
    [ShapeType.STORAGE_POSITION]: LoadHandlingType.STORAGE,
    [ShapeType.DELIVERY_POSITION]: LoadHandlingType.DELIVERY,
    [ShapeType.PROCESS_ONE_EP_POSITION]: LoadHandlingType.PROCESS,
    [ShapeType.CHARGING_POSITION]: StationaryTypeEnum.CHARGING,
    [ShapeType.PARKING_POSITION]: StationaryTypeEnum.PARKING,
  };

  return positionTypeMap[type] ?? '';
};

export const positionLoadMinValues = (
  boundingBox: FPBoundingBox,
  direction: AreaDirection,
  loadWidth: number,
  loadLength: number,
) => {
  if (direction === AreaDirection.DOWN) {
    return {
      minX: boundingBox.minX + (boundingBox.width - loadWidth) / 2,
      minY: boundingBox.minY + (boundingBox.length - loadLength),
    };
  }
  if (direction === AreaDirection.UP) {
    return {
      minX: boundingBox.minX + (boundingBox.width - loadWidth) / 2,
      minY: boundingBox.minY,
    };
  }
  if (direction === AreaDirection.LEFT) {
    return {
      minX: boundingBox.minX + (boundingBox.width - loadWidth),
      minY: boundingBox.minY + (boundingBox.length - loadLength) / 2,
    };
  }
  if (direction === AreaDirection.RIGHT) {
    return {
      minX: boundingBox.minX,
      minY: boundingBox.minY + (boundingBox.length - loadLength) / 2,
    };
  }
};

export const getFpsLoadCarriersBoundingBox = (
  loadCarrierTypes: LoadCarrierType[],
): { width: number; length: number } => {
  if (!loadCarrierTypes.length) {
    return DEFAULT_LOAD_BOUNDING_BOX_DIMENSIONS;
  }

  // get the max length and max width of the load carrier types
  let maxWidth = Math.max(...loadCarrierTypes.map((item) => item.width));
  let maxLength = Math.max(...loadCarrierTypes.map((item) => item.length));

  return {
    width: maxWidth,
    length: maxLength,
  };
};

export const calcFpsLoadPositionAngle = (
  orientation: AreaLoadCarrierOrientation,
  loadLengthIsLongestSide: boolean,
): number => {
  let angle: 0 | 90 = 0;

  if (loadLengthIsLongestSide && orientation === AreaLoadCarrierOrientation.SHORT_SIDE) {
    angle = 0;
  } else if (loadLengthIsLongestSide && orientation === AreaLoadCarrierOrientation.LONG_SIDE) {
    angle = 90;
  } else if (!loadLengthIsLongestSide && orientation === AreaLoadCarrierOrientation.SHORT_SIDE) {
    angle = 90;
  } else if (!loadLengthIsLongestSide && orientation === AreaLoadCarrierOrientation.LONG_SIDE) {
    angle = 0;
  }

  if (!loadLengthIsLongestSide) {
    angle = angle === 90 ? 0 : 90;
  }

  return angle;
};

export const loadCarrierTypesToFPLoadTypeReferences = (
  loadCarrierTypes: LoadCarrierType[],
): LoadTypeReferences =>
  loadCarrierTypes.map((item: LoadCarrierType) => ({
    name: item.name,
  }));

export const storageAlignment = (direction: AreaDirection) => {
  if (direction === AreaDirection.UP) {
    return AreaAlignment.BOTTOM; // alignmentToFPAlignment(AREA_ALIGNMENT.BOTTOM)
  }
  if (direction === AreaDirection.DOWN) {
    return AreaAlignment.TOP; // alignmentToFPAlignment(AREA_ALIGNMENT.TOP)
  }
  if (direction === AreaDirection.LEFT) {
    return AreaAlignment.BOTTOM; // alignmentToFPAlignment(AREA_ALIGNMENT.BOTTOM)
  }
  if (direction === AreaDirection.RIGHT) {
    return AreaAlignment.TOP; // alignmentToFPAlignment(AREA_ALIGNMENT.TOP)
  }
};

export const sideLoadingDelta = (direction: AreaDirection, deltaInput: Vector2): Vector2 => {
  if (direction === AreaDirection.DOWN) {
    return new Vector2(deltaInput.y, deltaInput.x);
  }
  if (direction === AreaDirection.UP) {
    return new Vector2(-deltaInput.y, -deltaInput.x);
  }
  if (direction === AreaDirection.LEFT) {
    return new Vector2(-deltaInput.x, deltaInput.y);
  }
  if (direction === AreaDirection.RIGHT) {
    return new Vector2(deltaInput.x, -deltaInput.y);
  }
};

export const areaStackingMode = (
  type: string,
  boundingBox: BoundingBox,
  direction: AreaDirection,
  epDirection: AreaEndpointDirection | null,
): AreaEndpointDirection | null => {
  if (type === 'HIGHWAY') return null;

  const isHorizontal = boundingBox.width >= boundingBox.height;

  if (!epDirection) {
    if (isHorizontal && (direction === AreaDirection.UP || direction === AreaDirection.DOWN)) {
      return AreaEndpointDirection.HORIZONTAL;
    }
    if (isHorizontal && (direction === AreaDirection.LEFT || direction === AreaDirection.RIGHT)) {
      return AreaEndpointDirection.VERTICAL;
    }
    if (!isHorizontal && (direction === AreaDirection.UP || direction === AreaDirection.DOWN)) {
      return AreaEndpointDirection.VERTICAL;
    }
    if (!isHorizontal && (direction === AreaDirection.LEFT || direction === AreaDirection.RIGHT)) {
      return AreaEndpointDirection.HORIZONTAL;
    }
  } else {
    // this is for side loading
    if (isHorizontal && epDirection === AreaEndpointDirection.HORIZONTAL) {
      return AreaEndpointDirection.HORIZONTAL;
    }
    if (isHorizontal && epDirection === AreaEndpointDirection.VERTICAL) {
      return AreaEndpointDirection.VERTICAL;
    }
    if (!isHorizontal && epDirection === AreaEndpointDirection.HORIZONTAL) {
      return AreaEndpointDirection.VERTICAL;
    }
    if (!isHorizontal && epDirection === AreaEndpointDirection.VERTICAL) {
      return AreaEndpointDirection.HORIZONTAL;
    }
  }
};

export const areHighwaysParallel = (directions: HighwayDirection[]): Boolean =>
  allEqual(directions);

export const areHighwaysAfterEachOther = (
  highwayA: LineSegment,
  highwayB: LineSegment,
  connectionAngle: number,
): Boolean => {
  if (!areLinesParallel(highwayA, highwayB)) return false;

  let highwaysAngle = -angleBetweenVectors(
    new Vector2().subVectors(highwayA.end, highwayA.start),
    new Vector2(1, 0),
  );

  if (highwaysAngle < 0) highwaysAngle += Math.PI;
  if (highwaysAngle === Math.PI) highwaysAngle = 0;
  if (connectionAngle == 180) connectionAngle = 0;

  return Math.abs(connectionAngle + highwaysAngle * RAD2DEG - 90) <= NEXT_OR_AFTER_CUTOFF;
};

export const areHighwaysNextToEachOther = (
  highwayA: LineSegment,
  highwayB: LineSegment,
  connectionAngle: number,
): Boolean => {
  if (!areLinesParallel(highwayA, highwayB)) return false;

  let highwaysAngle = -angleBetweenVectors(
    new Vector2().subVectors(highwayA.end, highwayA.start),
    new Vector2(1, 0),
  );

  if (highwaysAngle < 0) highwaysAngle += Math.PI;
  if (highwaysAngle === Math.PI) highwaysAngle = 0;

  return Math.abs(connectionAngle + highwaysAngle * RAD2DEG - 180) <= NEXT_OR_AFTER_CUTOFF;
};

// NOTE: not used
const dotProductBetweenHighways = (highwayA: LineSegment, highwayB: LineSegment): number => {
  const midPointA = midpoint(highwayA.start, highwayA.end);
  const midPointB = midpoint(highwayB.start, highwayB.end);
  const vectorA = new Vector2().subVectors(highwayA.end, highwayA.start).normalize();
  const vectorA2B = new Vector2().subVectors(midPointB, midPointA).normalize();
  return Math.abs(vectorA.dot(vectorA2B));
};

export const mapCheck = (map): any =>
  map === null ? null : map.areaReferencesToMap.length <= 0 ? null : map;

export const areHighwayAndAreaParallel = (
  highwayLine: LineSegment,
  areaDirection: AreaDirection,
): Boolean => {
  if (areaDirection === AreaDirection.UP || areaDirection === AreaDirection.DOWN) {
    return areLinesParallel(highwayLine, { start: new Vector2(0, 0), end: new Vector2(0, 1) });
  }
  return areLinesParallel(highwayLine, { start: new Vector2(0, 0), end: new Vector2(1, 0) });
};

export const isGetawayConnection = (
  areaDirection: AreaDirection,
  highwayLine: LineSegment,
  areaId: string,
  highwayConnections: any[],
): Boolean => {
  const numberOfConnectionsForArea = highwayConnections.filter(
    (connection) => areaId === connection.to,
  ).length;
  if (numberOfConnectionsForArea === 1) return false;

  return areHighwayAndAreaParallel(highwayLine, areaDirection);
};

export const doShapesIntersect = (lineA: WideLineSegment, lineB: WideLineSegment): Boolean => {
  const polygonA = lineToPolygon(lineA);
  const polygonB = lineToPolygon(lineB);
  const polygonAOffset = makeOffsetPoly(polygonA, POLYGON_OUTSET_SIZE);
  const polygonBOffset = makeOffsetPoly(polygonB, POLYGON_OUTSET_SIZE);

  if (!doPolygonsIntersect(polygonAOffset, polygonBOffset)) return null;
  const position = connectionPointBetweenPolygons(polygonAOffset, polygonBOffset);
  if (!position) {
    return false;
  }

  return true;
};

export const getAngledHighwaySegment = (
  highway: AngledHighwayShapePersisted,
  index: string,
): WideLineSegment => {
  const segments = getSegments(highway);

  if (segments.length < +index) return null;

  return segments[+index];
};

export const reflectorsToFpsReflectors = (reflectors: Reflector[]): FpsReflectorFlat[] =>
  reflectors.map((reflector) => ({
    reflectorId: `REFL${reflector.id.padStart(3, '0')}`,
    externalId: reflector.id,
    x: reflector.position.x,
    y: reflector.position.y,
    angle: reflector.angle,
    width: null,
    type: reflector.type,
  }));

export const endPointDiscType = (type: ShapeType, storageType: StorageType) =>
  type === ShapeType.CHARGING ||
  type === ShapeType.PARKING ||
  type === ShapeType.CHARGING_POSITION ||
  type === ShapeType.PARKING_POSITION
    ? 'STATIONARY_EP_GENERATION_SETTINGS'
    : storageType === StorageType.SIDELOADING
    ? 'TRANSFER_EP_GENERATION_SETTINGS'
    : storageType === StorageType.RACK
    ? 'NARROW_AISLE_RACK_ONE_SIDED_EP_GENERATION_SETTINGS'
    : storageType === StorageType.TWOSIDEDRACK
    ? 'NARROW_AISLE_RACK_TWO_SIDED_EP_GENERATION_SETTINGS'
    : 'BUFFER_EP_GENERATION_SETTINGS';

// TODO rack: temp helper to build a rackId based on the shape id. can be removed when shapes can reference the same rack template
export const getRackNameFromShapeId = (shapeId: string) => `${shapeId}_shape_specific_rack`;

/**
 * @description returns the LineSegment of the highway / segment, with traffic direction going from start to end
 */
const getUniDirectionalRoadSegment = (
  line: LineSegment,
  laneDir: LaneDirection.LEFT | LaneDirection.RIGHT,
  isHorizontal?: boolean,
): LineSegment => {
  if (
    // intended for regular highway
    (isHorizontal !== undefined && isHorizontal && laneDir === LaneDirection.LEFT) ||
    (isHorizontal !== undefined && !isHorizontal && laneDir === LaneDirection.RIGHT) ||
    // intended for angled highway
    (isHorizontal === undefined && laneDir === LaneDirection.RIGHT)
  ) {
    return {
      start: line.end,
      end: line.start,
    };
  }

  return line;
};

/**
 * @description determines - with assumed driving direction (from LineSegment.start to LineSegment.end) - if a U-turn can be made to another road based on the angle between them
 */
export const allowUTurnBetween2Roads = <
  RoadData extends { line: LineSegment; laneDir: LaneDirection; isHorizontal?: boolean },
>(
  from: RoadData,
  to: RoadData,
) => {
  if (from.laneDir === LaneDirection.LEFT_RIGHT || to.laneDir === LaneDirection.LEFT_RIGHT) {
    // only allow u-turn between roads with opposite direction for now
    return false;
  }

  const directionalFromSegment = getUniDirectionalRoadSegment(
    from.line,
    from.laneDir,
    from.isHorizontal,
  );
  const directionalToSegment = getUniDirectionalRoadSegment(to.line, to.laneDir, to.isHorizontal);

  const normalizedFromEnd = directionalFromSegment.end.clone().sub(directionalFromSegment.start);
  const normalizedToEnd = directionalToSegment.end.clone().sub(directionalToSegment.start);
  const angle = angleBetweenVectors(normalizedFromEnd, normalizedToEnd) * RAD2DEG;

  const sanitizedAngle = Math.abs(angle) % 360;

  const lowerBound = 135;
  const upperBound = 225;

  return sanitizedAngle >= lowerBound && sanitizedAngle <= upperBound;
};

export const getTwoSidedRackTwinConfig = (laneDirectionInAisle: LaneDirection) => {
  switch (laneDirectionInAisle) {
    case LaneDirection.LEFT:
      return TwinConfiguration.ONE_WAY_KEEP_SECOND;
    case LaneDirection.RIGHT:
      return TwinConfiguration.ONE_WAY_KEEP_FIRST;
    case LaneDirection.LEFT_RIGHT:
    default:
      return TwinConfiguration.TWO_WAY;
  }
};
