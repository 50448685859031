import cloneDeep from 'clone-deep';
import { Vector2d } from 'konva/lib/types';
import { Vector2 } from 'three';
import { v4 as uuid } from 'uuid';

import { capitalizeFirstLetter } from '@/helpers/string';
import { merge } from '@/helpers/utils';
import { getMiddlePoint, getVectorRotatedAroundPoint } from '@/modules/common/helpers/math';
import { lineSegmentIntersect } from '@/modules/connections/common/helpers';
import { LayoutFlow } from '@/modules/flows/types';
import { ProcessTwoEPShape } from '@/modules/processTwoEndPoint';
import {
  getDefaultCarrierSide,
  getDefaultDirection,
  getDefaultDistribution,
  getDefaultGap,
  getDefaultLoadPlacement,
  getDefaultMargin,
  getDefaultOperationTime,
} from '@/modules/shapes/helpers/defaults';
import { UserPreferenceName, getUserPreference } from '@/modules/userPreferences';
import { addCopy, formatShapeType } from '@/store/recoil/floorplan/helper';
import { BoundingBox, OrientedBoundingBox } from '@helpers/types';
import { getBoundingBoxBoundaries } from '@modules/angledHighways';
import { AngledHighwayShape } from '@modules/angledHighways/types';
import { SHAPE_PROPERTIES_DEFAULT } from '@modules/common/constants/shapes';
import { DTShapePersisted } from '@modules/common/types/floorPlan';
import { isPointsShape } from '@modules/common/types/guards';
import {
  AreaAlignment,
  AreaDirection,
  AreaParkingDirection,
  ControlPoint,
  DTShape,
  LaneDirection,
  ProcessAreaOneEp,
  ShapeType,
} from '@modules/common/types/shapes';
import { StorageType } from '@modules/common/types/storage';
import { CANVAS_TO_SHAPE_SCALE, SHAPE_TO_CANVAS_SCALE } from '@modules/workspace/helpers/konva';
import { AreaShape, PositionShape, WallShape } from '@recoil/shape';
import { AREA_OVERFLOW } from '@recoil/workspace';
import {
  boundingBoxToLine,
  calculateOrientedBoundingBox,
  calculatePointsShapeBoundingBox,
} from './boundingBox';
import { LineSegment } from '@/modules/common/types/general';

export const isArea = (type) =>
  [
    ShapeType.INTAKE,
    ShapeType.STORAGE,
    ShapeType.DELIVERY,
    ShapeType.PROCESS_ONE_EP,
    ShapeType.HANDOVER,
    ShapeType.CHARGING,
    ShapeType.PARKING,
  ].includes(type);

export const isRoad = (type) => [ShapeType.HIGHWAY, ShapeType.HIGHWAY_ANGLED].includes(type);

export const isPosition = (type) =>
  [
    ShapeType.INTAKE_POSITION,
    ShapeType.STORAGE_POSITION,
    ShapeType.DELIVERY_POSITION,
    ShapeType.PROCESS_ONE_EP_POSITION,
    ShapeType.CHARGING_POSITION,
    ShapeType.PARKING_POSITION,
  ].includes(type);

export const isDrawable = (type) =>
  [
    ShapeType.WALL,
    ShapeType.INTAKE,
    ShapeType.STORAGE,
    ShapeType.DELIVERY,
    ShapeType.PROCESS_ONE_EP,
    ShapeType.PROCESS_TWO_EP,
    ShapeType.HANDOVER,
    ShapeType.CHARGING,
    ShapeType.PARKING,
    ShapeType.HIGHWAY,
    ShapeType.HIGHWAY_ANGLED,
    ShapeType.NONE,
    ShapeType.OBSTACLE,
    ShapeType.LASSIE,
  ].includes(type);

export const isPlaceable = (type) =>
  [
    ShapeType.INTAKE_POSITION,
    ShapeType.STORAGE_POSITION,
    ShapeType.DELIVERY_POSITION,
    ShapeType.PROCESS_ONE_EP_POSITION,
    ShapeType.CHARGING_POSITION,
    ShapeType.PARKING_POSITION,
  ].includes(type);

export const isGroupable = (type: ShapeType) =>
  [
    ShapeType.DELIVERY,
    ShapeType.DELIVERY_POSITION,
    ShapeType.INTAKE,
    ShapeType.INTAKE_POSITION,
    ShapeType.STORAGE,
    ShapeType.STORAGE_POSITION,
    ShapeType.PROCESS_ONE_EP,
    ShapeType.PROCESS_ONE_EP_POSITION,
    ShapeType.WALL,
  ].includes(type);

export const supportsLoadCarriers = (type: ShapeType) =>
  [
    ShapeType.INTAKE,
    ShapeType.INTAKE_POSITION,
    ShapeType.STORAGE,
    ShapeType.STORAGE_POSITION,
    ShapeType.DELIVERY,
    ShapeType.DELIVERY_POSITION,
    ShapeType.PROCESS_ONE_EP,
    ShapeType.PROCESS_ONE_EP_POSITION,
    ShapeType.PROCESS_TWO_EP,
    ShapeType.HANDOVER,
  ].includes(type);

export const supportsVehicleTypes = (type: ShapeType) =>
  [
    ShapeType.INTAKE,
    ShapeType.INTAKE_POSITION,
    ShapeType.STORAGE,
    ShapeType.STORAGE_POSITION,
    ShapeType.DELIVERY,
    ShapeType.DELIVERY_POSITION,
    ShapeType.PROCESS_ONE_EP,
    ShapeType.PROCESS_ONE_EP_POSITION,
    ShapeType.PROCESS_TWO_EP,
    ShapeType.CHARGING,
    ShapeType.CHARGING_POSITION,
    ShapeType.PARKING,
    ShapeType.PARKING_POSITION,
  ].includes(type);

export const usesOperationTime = (type: ShapeType) =>
  [ShapeType.PROCESS_ONE_EP, ShapeType.PROCESS_TWO_EP, ShapeType.HANDOVER].includes(type);

export const usesLoadPlacement = (type: ShapeType) =>
  [ShapeType.PROCESS_ONE_EP, ShapeType.PROCESS_TWO_EP].includes(type);

// TODO: process two ep ?
export const usesControlPoints = (type) =>
  [ShapeType.HIGHWAY_ANGLED, ShapeType.WALL, ShapeType.PROCESS_TWO_EP].includes(type);

export const calculateShapesBoundingBox = (
  shapes: readonly DTShape[] | readonly DTShapePersisted[],
): BoundingBox =>
  calculateOrientedBoundingBox(
    shapes.map((shape) => {
      if (isPointsShape(shape)) {
        return {
          ...calculatePointsShapeBoundingBox(
            shape.properties.controlPoints.map((cp) => cp.position),
            shape.parameters.width,
          ),
          r: 0,
        };
      }

      return shape.properties;
    }),
  );

/**
 * Looks for a combined parameter value.
 *
 * @param shapes List of shapes
 * @param name Name of a parameter to look for
 * @returns Value if all parameters share the same value, undefined otherwise
 */
export const findParameterValue = (shapes: DTShape[], name: string) => {
  if (!shapes.length) {
    return;
  }

  const firstShape = shapes[0];
  const firstValue = firstShape.parameters[name];

  const sameValues = shapes.slice(1).every((shape) => {
    const value = shape.parameters[name];

    return value === firstValue;
  });

  // Note: Don't return undefined. This makes components become "uncontrolled". When value changes to anything but undefined it will become "controlled"
  // Not sure if false is the best return value here but testing things
  return sameValues ? (firstValue === undefined ? false : firstValue) : false;
};

/**
 * Returns a position on the shape where the connection arrow can attach itself
 *
 * @param shape A position or an area
 */
export const getShapeAttachPoint = (shape: AreaShape | PositionShape, scale = true): Vector2d => {
  const direction = findParameterValue([shape], 'direction');
  const offset = getFlowStopOffsetMultiplier(direction);
  const unrotatedPoint = new Vector2(
    shape.properties.x + shape.properties.width * offset.x,
    shape.properties.y + shape.properties.height * offset.y,
  );

  const centerOfRotation = new Vector2(shape.properties.x, shape.properties.y);
  const point = getVectorRotatedAroundPoint(
    unrotatedPoint,
    centerOfRotation,
    -shape.properties.r,
  )
  
  if (scale) return point.multiplyScalar(SHAPE_TO_CANVAS_SCALE)

  return point;
};

export const getFlowStopOffsetMultiplier = (shapeDirection: AreaDirection): Vector2d => {
  // on a scale of 0 to 1, start dead-center
  const offset = { x: 0.5, y: 0.5 };

  switch (shapeDirection) {
    case AreaDirection.UP:
      offset.y = 0;
      break;
    case AreaDirection.DOWN:
      offset.y = 1;
      break;
    case AreaDirection.RIGHT:
      offset.x = 1;
      break;
    case AreaDirection.LEFT:
      offset.x = 0;
      break;
    default:
  }

  return offset;
};

const getName = (shapeType: ShapeType): string => {
  const type = formatShapeType(shapeType);
  const index = parseInt(sessionStorage.getItem(type) ?? '1');
  sessionStorage.setItem(type, (index + 1).toString());
  return `${type
    .split('_')
    .map((item) => capitalizeFirstLetter(item))
    .join(' ')} ${index}`;
};

export const defaultShapeFactory = (
  id: string,
  type: ShapeType,
  supportedVehicleIds: string[],
  supportedLoadCarriersIds: string[],
  vehicleWidth: number,
  vehicleLength: number,
): DTShape => {
  const name = getName(type);
  switch (type) {
    case ShapeType.OBSTACLE: {
      return {
        id,
        name,
        type: ShapeType.OBSTACLE,
        disabled: false,
        isDrawing: true,
        isLoading: false,
        isTransforming: false,
        properties: cloneDeep(SHAPE_PROPERTIES_DEFAULT),
        parameters: {
          height: getUserPreference(UserPreferenceName.BUILDING_OBSTACLE_HEIGHT),
          obstacleType: getUserPreference(UserPreferenceName.BUILDING_OBSTACLE_TYPE),
        },
      };
    }
    case ShapeType.WALL: {
      return {
        id,
        name,
        type: ShapeType.WALL,
        disabled: false,
        isDrawing: true,
        isLoading: false,
        isTransforming: false,
        properties: {
          controlPoints: [],
        },
        parameters: {
          direction: 0,
          height: getUserPreference(UserPreferenceName.BUILDING_WALL_HEIGHT),
          material: getUserPreference(UserPreferenceName.BUILDING_WALL_MATERIAL),
          width: getUserPreference(UserPreferenceName.BUILDING_WALL_THICKNESS),
        },
      };
    }
    case ShapeType.HIGHWAY: {
      return {
        id,
        name,
        type: ShapeType.HIGHWAY,
        disabled: false,
        isDrawing: true,
        isLoading: false,
        isTransforming: false,
        properties: cloneDeep(SHAPE_PROPERTIES_DEFAULT),
        parameters: {
          laneDirection: LaneDirection.LEFT_RIGHT,
          gap: getDefaultGap(type),
          margin: getDefaultMargin(type),
          routingPointMarginToCrossing: getUserPreference(
            UserPreferenceName.HIGHWAY_MARGIN_TO_CROSSING,
          ),
          routingPointGroupMinGap: vehicleLength * 10,
          vehicleLimit: -1,
          supportedVehicleIds: [],
        },
      };
    }
    case ShapeType.HIGHWAY_ANGLED: {
      return {
        id,
        name,
        type: ShapeType.HIGHWAY_ANGLED,
        disabled: false,
        isDrawing: true,
        isLoading: false,
        isTransforming: false,
        properties: {
          controlPoints: [],
        },
        parameters: {
          width: getUserPreference(UserPreferenceName.HIGHWAY_WIDTH),
          laneDirection: LaneDirection.LEFT_RIGHT,
          lanes: 0,
          gap: getDefaultGap(type),
          margin: getDefaultMargin(type),
          routingPointMarginToCrossing: getUserPreference(
            UserPreferenceName.HIGHWAY_MARGIN_TO_CROSSING,
          ),
          routingPointGroupMinGap: vehicleLength * 10,
          vehicleLimit: -1,
          supportedVehicleIds: [],
        },
      };
    }
    case ShapeType.DELIVERY:
    case ShapeType.INTAKE:
    case ShapeType.PARKING:
    case ShapeType.PROCESS_ONE_EP:
    case ShapeType.HANDOVER:
    case ShapeType.STORAGE:
    case ShapeType.CHARGING: {
      return {
        id,
        name,
        type,
        disabled: false,
        isDrawing: true,
        isLoading: false,
        isTransforming: false,
        properties: cloneDeep(SHAPE_PROPERTIES_DEFAULT),
        parameters: {
          direction: getDefaultDirection(type),
          alignment: AreaAlignment.CENTER,
          positionOverflow: AREA_OVERFLOW.CONTAIN,
          distribution: getDefaultDistribution(type),
          gap: getDefaultGap(type),
          margin: getDefaultMargin(type),
          gapHorizontal: 0,
          gapVertical: 0,
          multiDeep: false,
          supportedVehicleIds,
          supportedLoadCarriersIds,
          loadCarrierOrientation: supportsLoadCarriers(type) ? getDefaultCarrierSide(type) : null,
          storageType: StorageType.SINGLE,
          storageProperty: null,
          parkingDirection: AreaParkingDirection.BACKWARD,
          loadElevation: 0,
          operationTime: getDefaultOperationTime(type),
          loadPlacement: getDefaultLoadPlacement(type),
        },
      };
    }
    case ShapeType.PROCESS_TWO_EP: {
      return {
        id,
        name,
        type,
        disabled: false,
        isDrawing: true,
        isLoading: false,
        isTransforming: false,
        properties: {
          controlPoints: [],
        },
        parameters: {
          direction: getDefaultDirection(type),
          alignment: AreaAlignment.CENTER,
          positionOverflow: AREA_OVERFLOW.CONTAIN,
          distribution: getDefaultDistribution(type),
          gap: getDefaultGap(type),
          margin: getDefaultMargin(type),
          gapHorizontal: 0,
          gapVertical: 0,
          multiDeep: false,
          supportedVehicleIds,
          supportedLoadCarriersIds,
          loadCarrierOrientation: supportsLoadCarriers(type) ? getDefaultCarrierSide(type) : null,
          storageType: StorageType.SINGLE,
          storageProperty: null,
          parkingDirection: AreaParkingDirection.BACKWARD,
          loadElevation: 0,
          operationTime: getDefaultOperationTime(type),
          loadPlacement: getDefaultLoadPlacement(type),
          width: getUserPreference(UserPreferenceName.PROCESS_WIDTH),
        },
      };
    }
    case ShapeType.INTAKE_POSITION:
    case ShapeType.DELIVERY_POSITION:
    case ShapeType.STORAGE_POSITION:
    case ShapeType.CHARGING_POSITION:
    case ShapeType.PARKING_POSITION:
    case ShapeType.PROCESS_ONE_EP_POSITION: {
      return {
        id,
        name,
        type,
        disabled: false,
        isDrawing: false,
        isLoading: false,
        isTransforming: false,
        properties: {
          ...cloneDeep(SHAPE_PROPERTIES_DEFAULT),
          width: vehicleWidth ? vehicleWidth * 10 : 0,
          height: vehicleLength ? vehicleLength * 10 : 0,
        },
        parameters: {
          alignment: AreaAlignment.CENTER,
          direction: getDefaultDirection(type),
          margin: getDefaultMargin(type),
          gap: 0,
          supportedVehicleIds,
          supportedLoadCarriersIds: supportsLoadCarriers(type) ? supportedLoadCarriersIds : [],
          loadCarrierOrientation: supportsLoadCarriers(type) ? getDefaultCarrierSide(type) : null,
          loadElevation: 0,
          operationTime: getDefaultOperationTime(type),
          loadPlacement: getDefaultLoadPlacement(type),
        },
      };
    }
    case ShapeType.LASSIE:
      console.log('LASSIE shapetype unhandled in shape factory')
      break;
    case ShapeType.NONE:
      console.error(`Can not provide a default shape for shapetype: ${type}`);
      break;
    default: {
      // error if not all options are covered
      return type;
    }
  }
};

export const getBoxAttachPointRelativeTo = (
  box: OrientedBoundingBox,
  relativeTo: Vector2,
): Vector2 | null => {
  const {
    points: { start, end },
  } = boundingBoxToLine(box);
  const fromShapeMiddlePoint = getMiddlePoint(start, end);

  const centerOfRotation = new Vector2(box.x, box.y);
  const orientedBbBoundaries = getBoundingBoxBoundaries(box).map(
    (item): LineSegment => ({
      start: getVectorRotatedAroundPoint(item.start, centerOfRotation, -box.r).multiplyScalar(
        SHAPE_TO_CANVAS_SCALE,
      ),
      end: getVectorRotatedAroundPoint(item.end, centerOfRotation, -box.r).multiplyScalar(
        SHAPE_TO_CANVAS_SCALE,
      ),
    }),
  );

  const endShapeAttachPoint = new Vector2(
    relativeTo.x * CANVAS_TO_SHAPE_SCALE,
    relativeTo.y * CANVAS_TO_SHAPE_SCALE,
  );
  const distconLineWithUnclippedStart: LineSegment = {
    start: fromShapeMiddlePoint,
    end: endShapeAttachPoint,
  };
  distconLineWithUnclippedStart.start.multiplyScalar(SHAPE_TO_CANVAS_SCALE);
  distconLineWithUnclippedStart.end.multiplyScalar(SHAPE_TO_CANVAS_SCALE);
  const intersectingBoundary = orientedBbBoundaries.find((item) =>
    lineSegmentIntersect(item, distconLineWithUnclippedStart),
  );

  if (!intersectingBoundary) return null;

  return lineSegmentIntersect(intersectingBoundary, {
    start: new Vector2(relativeTo.x, relativeTo.y),
    end: fromShapeMiddlePoint,
  });
};

type processRelatedFlowMap = Map<
  string,
  { processAreaId: string; inbound: LayoutFlow[]; outbound: LayoutFlow[]; processingTime: number }
>;

type processRelatedSimFlowMap = Map<
  string,
  { processAreaId: string; inbound: string[]; outbound: string[]; processingTime: number }
>;

export const createProcessRelatedFlowsMap = (
  processAreas: ProcessAreaOneEp[],
  flows: LayoutFlow[],
): processRelatedFlowMap => {
  const processAreaNames = new Set(processAreas.map((item) => item.name));
  const processRelatedFlowMap: processRelatedFlowMap = new Map();

  flows.forEach((flow: LayoutFlow) => {
    if (processAreaNames.has(flow.targetName)) {
      const processRelatedFlows = processRelatedFlowMap.get(flow.targetName);
      if (processRelatedFlows) {
        processRelatedFlows.inbound.push(flow);
      } else {
        const processArea = processAreas.find((item) => item.name === flow.targetName);
        processRelatedFlowMap.set(flow.targetName, {
          processAreaId: processArea.id,
          inbound: [flow],
          outbound: [],
          processingTime: processArea.parameters.operationTime,
        });
      }
    }

    if (processAreaNames.has(flow.sourceName)) {
      const processRelatedFlows = processRelatedFlowMap.get(flow.sourceName);
      if (processRelatedFlows) {
        processRelatedFlows.outbound.push(flow);
      } else {
        const processArea = processAreas.find((item) => item.name === flow.sourceName);
        processRelatedFlowMap.set(flow.sourceName, {
          processAreaId: processArea.id,
          inbound: [],
          outbound: [flow],
          processingTime: processArea.parameters.operationTime,
        });
      }
    }
  });

  return processRelatedFlowMap;
};

export const createProcessRelatedSimFlowsMap = (
  processAreas: ProcessAreaOneEp[],
  flows: LayoutFlow[],
): processRelatedSimFlowMap => {
  const processAreaNames = new Set(processAreas.map((item) => item.name));
  const processRelatedFlowMap: processRelatedSimFlowMap = new Map();

  flows.forEach((flow) => {
    if (processAreaNames.has(flow.targetName)) {
      const processRelatedFlows = processRelatedFlowMap.get(flow.targetName);
      if (processRelatedFlows) {
        processRelatedFlows.inbound.push(flow.name);
      } else {
        const processArea = processAreas.find((item) => item.name === flow.targetName);
        processRelatedFlowMap.set(flow.targetName, {
          processAreaId: processArea.id,
          inbound: [flow.name],
          outbound: [],
          processingTime: processArea.parameters.operationTime,
        });
      }
    }

    if (processAreaNames.has(flow.sourceName)) {
      const processRelatedFlows = processRelatedFlowMap.get(flow.sourceName);
      if (processRelatedFlows) {
        processRelatedFlows.outbound.push(flow.name);
      } else {
        const processArea = processAreas.find((item) => item.name === flow.sourceName);
        processRelatedFlowMap.set(flow.sourceName, {
          processAreaId: processArea.id,
          inbound: [],
          outbound: [flow.name],
          processingTime: processArea.parameters.operationTime,
        });
      }
    }
  });

  return processRelatedFlowMap;
};

export const getOrderedControlPoints = (controlPoints: ControlPoint[]): ControlPoint[] => {
  const sourceChainControlPoints = [...controlPoints];
  const tempOrdered = [];

  while (sourceChainControlPoints.length !== 0) {
    for (let i = 0; i < sourceChainControlPoints.length; i++) {
      const currentControlPoint = sourceChainControlPoints[i];

      // identify the head
      // TODO: move outside of while loop since no need to check again and again
      if (currentControlPoint.prev === null) {
        tempOrdered.push(...sourceChainControlPoints.splice(i, 1));
        break;
      }

      // if next controlPoint follows the previous one, move it to ordered array
      const prevOrderedControlPointId = tempOrdered[tempOrdered.length - 1].id;
      if (currentControlPoint.prev === prevOrderedControlPointId) {
        tempOrdered.push(...sourceChainControlPoints.splice(i, 1));
        break;
      }
    }
  }

  return tempOrdered;
};

export const getControlPointShapeDuplicate = <
  T extends AngledHighwayShape | ProcessTwoEPShape | WallShape,
>(
  originShape: T,
  offset: Vector2,
  overrides: any = {},
): T => {
  const newShapeControlPoints = [];

  let prevCPId = null;
  let newCPId = null;
  let nextCPId = null;

  const controlPointsAmount = originShape.properties.controlPoints.length;
  getOrderedControlPoints(originShape.properties.controlPoints).forEach((originCP, index) => {
    newCPId = nextCPId || uuid();

    const isLastCP = controlPointsAmount - 1 === index;
    nextCPId = isLastCP ? null : uuid();

    const newCP: ControlPoint = {
      id: newCPId,
      prev: prevCPId,
      next: nextCPId,
      position: new Vector2(originCP.position.x, originCP.position.y).add(offset),
    };

    prevCPId = newCPId;

    newShapeControlPoints.push(newCP);
  });

  return merge(
    {
      ...originShape,
      name: addCopy(originShape),
      id: uuid(),
      properties: {
        ...originShape.properties,
        controlPoints: newShapeControlPoints,
      },
    },
    overrides,
  );
};
