import { Vector2 } from 'three';
import { RAD2DEG } from 'three/src/math/MathUtils';

import { boundingBoxToLine } from '@/modules/common/helpers/boundingBox';
import { doPolygonsIntersect, lineToPolygon, makeOffsetPoly } from '@/modules/common/helpers/polygon';
import { Polygon } from '@/modules/connections/common/types';
import { Position } from '@helpers/types';
import { getSegments } from '@modules/angledHighways/helpers';
import { WideLineSegment } from '@/modules/common/types/general';
import { isArea, isPosition } from '@modules/common/helpers/shapes';
import { isAngledHighwayShape, isHighwayShape, isProcessAreaTwoEp, isWallShape } from '@modules/common/types/guards';
import { DTShape, ShapeType } from '@modules/common/types/shapes';
import {
  connectionPointBetweenPolygons,
  findClosestSegmentToPoint
} from '@modules/connections/common/helpers';
import { angleBetweenVectors, areLinesParallel } from '@modules/workspace/helpers/shape';

const POLYGON_OUTSET_SIZE = -50; // minus to make object bigger

export type ConnectionPosition = {
  position: Position;
  rot: number;
  from: string;
  to: string;
};

export type ConnectionPoint = {
  position: Position;
  rot: number;
};

export const findConnectionsPositions = (
  shape: DTShape,
  shapes: DTShape[],
): ConnectionPosition[] => {
  const connections: ConnectionPosition[] = [];

  // find overlap from other area to this area
  // eslint-disable-next-line no-restricted-syntax
  for (const otherShape of shapes) {
    if (shape.id === otherShape.id) continue;

    const connection = findConnectionPosition(shape, otherShape);
    if (connection) {
      connections.push(connection);
    }
  }

  return connections;
};

export const findConnectionPosition = (
  shape1: DTShape,
  shape2: DTShape,
): ConnectionPosition | null => {
  if (shape1.id === shape2.id) return null;

  // area doesn't connect to area or position
  if (
    (isArea(shape1.type) || isPosition(shape1.type)) &&
    (isArea(shape2.type) || isPosition(shape2.type))
  )
    return null;

    // TODO: process two ep. connection support. Need a fn that returns the obb for an endpoint
  if (isProcessAreaTwoEp(shape1) || isProcessAreaTwoEp(shape2)) return null;

  if (isWallShape(shape1) || isWallShape(shape2)) return null;

  const shapeA =
    shape1.type === ShapeType.HIGHWAY_ANGLED
      ? getSegments(shape1)
      : [boundingBoxToLine(shape1.properties)];

  const shapeB =
    shape2.type === ShapeType.HIGHWAY_ANGLED
      ? getSegments(shape2)
      : [boundingBoxToLine(shape2.properties)];

  for (let indexA = 0; indexA < shapeA.length; indexA++) {
    const subShapeA = shapeA[indexA];

    for (let indexB = 0; indexB < shapeB.length; indexB++) {
      const subShapeB = shapeB[indexB];

      // highways in different directions doesn't connect to each other, see crossings
      if (
        (isHighwayShape(shape1) || isAngledHighwayShape(shape1)) &&
        (isHighwayShape(shape2) || isAngledHighwayShape(shape2)) &&
        !areLinesParallel(subShapeA.points, subShapeB.points)
      )
        continue;

      const connection = getConnectionPoint(subShapeA, subShapeB);
      if (connection) {
        return {
          ...connection,
          from: shape1.id + (shape1.type === ShapeType.HIGHWAY_ANGLED ? `.${indexA}` : ''),
          to: shape2.id + (shape2.type === ShapeType.HIGHWAY_ANGLED ? `.${indexB}` : ''),
        };
      }
    }
  }

  return null;
};

const getConnectionPoint = (
  lineA: WideLineSegment,
  lineB: WideLineSegment,
): ConnectionPoint | null => {
  const polygonA = lineToPolygon(lineA);
  const polygonB = lineToPolygon(lineB);
  const polygonAOffset = makeOffsetPoly(polygonA, POLYGON_OUTSET_SIZE);
  const polygonBOffset = makeOffsetPoly(polygonB, POLYGON_OUTSET_SIZE);

  if (!doPolygonsIntersect(polygonAOffset, polygonBOffset)) return null;
  const position = connectionPointBetweenPolygons(polygonAOffset, polygonBOffset);
  if (!position) {
    return null;
  }

  return {
    position,
    rot: connectionRotation(polygonAOffset, position),
  };
};

const connectionRotation = (objectA: Polygon, point: Vector2): number => {
  const segmentA = findClosestSegmentToPoint(objectA, point);
  const vectorA = segmentA.end.clone().sub(segmentA.start).normalize().multiplyScalar(-1);
  return angleBetweenVectors(vectorA, new Vector2(1, 0)) * RAD2DEG
};
