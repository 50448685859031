import { ShapeType } from '@/modules/common/types/shapes';
import { TemplateType } from './types';

export const mapTemplateToolToTemplateType = (tool: ShapeType): TemplateType | null => {
  switch (tool) {
    case ShapeType.LASSIE:
      return TemplateType.LASSIE;
    default:
      return null;
  }
};

export const generateTemplateGroupName = (templateType: TemplateType): string => {
  const storageKey = templateType.replace(' ', '_');

  const index = parseInt(sessionStorage.getItem(storageKey) ?? '1');
  sessionStorage.setItem(storageKey, (index + 1).toString());

  return `${storageKey.substring(0, 2).toUpperCase()}${String(index).padStart(2, '0')}`;
};
