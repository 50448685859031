import { Connection, DTShapePersisted } from '@/modules/common/types/floorPlan';
import { IdGenerator } from '@/modules/floorplanService';

export enum TemplateType {
  LASSIE = 'lassie',
}

export type GenerationParams = {
  x: number;
  y: number;
  angle: number;
  numAisles: number;
  numBays: number;
  numShelves: number;
};

export type GenerationResponse = GenerationSuccess | GenerationFail;

type GenerationSuccess = {
  success: true;
  data: TemplateData;
};

type GenerationFail = {
  success: false;
};

type TemplateData = {
  connections: Connection[];
  distcons: Connection[];
  shapes: DTShapePersisted[];
  idGenerators: IdGenerator[];
  unit?: string;
};
