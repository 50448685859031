import { Stack } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import ProcessTwoEndPointPropery from '@/components/PropertiesPanel/layout/process/ProcessTwoEndPointPropery';
import { FlowsPanel, RelatedFlows } from '@/modules/flows/layout';
import { LayerProperties } from '@/modules/layers/components/LayerProperties';
import {
  selectedShapesIdsState,
  selectionContainsFlowInteractivity,
} from '@/store/recoil/shapes/selected';
import { InfoText } from '@common/components/InfoText';
import { PreValidation } from '@modules/floorplanValidation/clientSide';
import { GroupsProperties } from '@modules/shapeGroups/components/GroupsProperties';
import Header from '../Header';
import ShapeDimensions from '../ShapeDimensions';
import { PanelDisplayMode, panelModeState } from '../store/panel';
import AngledHighwayProperty from './AngledHighwayProperty';
import AreaProperty from './AreaProperty';
import HighwayProperty from './HighwayProperty';
import InfoCard from './InfoCard/InfoCard';
import ObstacleProperty from './ObstacleProperty';
import PositionProperty from './PositionProperty';
import WallProperties from './WallProperties';
import { TemplateType } from '@/modules/templating';
import { TemplateProperty } from './TemplateProperty';

function LayoutProperties() {
  const { t } = useTranslation('interface');
  const panelMode = useRecoilValue(panelModeState);
  const showRelatedFlows = useRecoilValue(selectionContainsFlowInteractivity);
  const selectedShapes = useRecoilValue(selectedShapesIdsState);

  if (panelMode === PanelDisplayMode.NONE) {
    return (
      <Stack height='calc(100% - 80px)' gap={0}>
        <PreValidation />
        <Stack
          alignItems='center'
          justifyContent='center'
          sx={{ padding: '46px 16px 46px 16px', '&:hover': { cursor: 'default' } }}
        >
          <InfoText header={t('properties.nothing_selected')} />
        </Stack>
        <Divider />
        <Stack
          spacing={1}
          divider={<Divider />}
          sx={{
            overflowY: 'scroll',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <FlowsPanel />
          <GroupsProperties />
          <LayerProperties />
          <>{/* Intentionally Empty */}</>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack spacing={1} height='calc(100% - 80px)' data-testid='LayoutProperties'>
      <PreValidation />
      <Header />
      <Stack
        spacing={1}
        divider={<Divider />}
        sx={{
          overflowY: 'scroll',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <>{/* Intentionally Empty */}</>
        {panelMode === PanelDisplayMode.AREA && selectedShapes.length === 1 && <InfoCard />}
        <ShapeDimensions />
        {panelMode === PanelDisplayMode.OBSTACLES && <ObstacleProperty />}
        {panelMode === PanelDisplayMode.WALLS && <WallProperties />}
        {panelMode === PanelDisplayMode.AREA && <AreaProperty />}
        {panelMode === PanelDisplayMode.HIGHWAYS && <HighwayProperty />}
        {panelMode === PanelDisplayMode.ANGLED_HIGHWAYS && <AngledHighwayProperty />}
        {panelMode === PanelDisplayMode.PROCESS_TWO_EP && <ProcessTwoEndPointPropery />}
        {panelMode === PanelDisplayMode.POSITIONS && <PositionProperty />}
        {panelMode === PanelDisplayMode.TEMPLATE_LASSIE && <TemplateProperty type={TemplateType.LASSIE} />}
        {showRelatedFlows && <RelatedFlows />}
        <GroupsProperties />
        <LayerProperties />
        <>{/* Intentionally Empty */}</>
      </Stack>
    </Stack>
  );
}

export default LayoutProperties;
