import { Divider, Stack, Button } from '@mui/material';
import { memo, useCallback, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

import { Accordion } from '@common/components/Accordion';
import { PropertiesLabel } from '@common/components/PropertiesLabel';
import { TemplateType, useTemplateGenerationCallbacks } from '@/modules/templating';
import { TemplateNumberInput } from './TemplateNumberInput';
import { selectedGroupIdsState, selectedGroupsSelector } from '@/modules/shapeGroups';
import { LassieGroup } from '@/modules/common/types/shapeGroup';
import { LoadingType } from '@/modules/common/types/general';
import { useZoomButton } from '@/modules/workspace/components/ZoomButton/useZoomButton';
import { useLoading } from '@/modules/common/hooks';
import { selectedShapesIdsState } from '@/store/recoil/shapes/selected';
import { useUpdateUserPreferences } from '@/modules/userPreferences/hooks';
import { UserPreferenceName } from '@/modules/userPreferences';
import { useFloorPlanState } from '@/modules/floorplan';

const TemplatePropertyComponent = ({ type }: { type: TemplateType }) => (
  <Accordion
    title={type}
    titleSx={{ textTransform: 'capitalize' }}
    defaultExpanded
    unmountOnExit
    sx={{ px: 2 }}
  >
    <Stack direction='column' spacing={1} divider={<Divider sx={{ padding: '4px 0px' }} />}>
      {type === TemplateType.LASSIE && <LassiePropertyContent />}
    </Stack>
  </Accordion>
);

const LassiePropertyContent = () => {
  const { t } = useTranslation();
  const { updateUserPreference } = useUpdateUserPreferences();
  const { saveFloorPlan } = useFloorPlanState();
  const lassieGroup = useRecoilValue(selectedGroupsSelector)[0] as LassieGroup;
  const { showLoader, hideLoader } = useLoading();
  const { zoomFit } = useZoomButton();
  const { createTemplate } = useTemplateGenerationCallbacks();
  const setSelectedShapesIds = useSetRecoilState(selectedShapesIdsState);
  const setSelectedGroupIds = useSetRecoilState(selectedGroupIdsState);

  const [newGenerationParams, setNewGenerationParams] = useState({
    ...lassieGroup.generationParams,
  });

  const updatePreferences = useCallback(() => {
    if (lassieGroup.generationParams.numAisles !== newGenerationParams.numAisles)
      updateUserPreference(
        UserPreferenceName.TEMPLATE_LASSIE_NUM_AISLES,
        newGenerationParams.numAisles,
        false,
      );
    if (lassieGroup.generationParams.numBays !== newGenerationParams.numBays)
      updateUserPreference(
        UserPreferenceName.TEMPLATE_LASSIE_NUM_BAYS,
        newGenerationParams.numBays,
        false,
      );
    if (lassieGroup.generationParams.numShelves !== newGenerationParams.numShelves)
      updateUserPreference(
        UserPreferenceName.TEMPLATE_LASSIE_NUM_SHELVES,
        newGenerationParams.numShelves,
        false,
      );
  }, [
    updateUserPreference,
    lassieGroup.generationParams,
    newGenerationParams.numAisles,
    newGenerationParams.numBays,
    newGenerationParams.numShelves,
  ]);

  const regenerate = useCallback(async () => {
    showLoader(LoadingType.TRANSPARENT);
    const group = await createTemplate(TemplateType.LASSIE, newGenerationParams, lassieGroup.id);
    setSelectedShapesIds(group.children);
    setSelectedGroupIds([group.id]);
    zoomFit();
    hideLoader();
    saveFloorPlan();
    updatePreferences();
  }, [
    showLoader,
    createTemplate,
    newGenerationParams,
    lassieGroup.id,
    setSelectedShapesIds,
    setSelectedGroupIds,
    zoomFit,
    hideLoader,
    updatePreferences,
    saveFloorPlan,
  ]);

  const onNumAislesChange = useCallback((newValue) => {
    setNewGenerationParams((current) => ({ ...current, numAisles: newValue }));
  }, []);
  const onNumBaysChange = useCallback((newValue) => {
    setNewGenerationParams((current) => ({ ...current, numBays: newValue }));
  }, []);
  const onNumShelvesChange = useCallback((newValue) => {
    setNewGenerationParams((current) => ({ ...current, numShelves: newValue }));
  }, []);

  const regenerationDisabled =
    newGenerationParams.numAisles === lassieGroup.generationParams.numAisles &&
    newGenerationParams.numBays === lassieGroup.generationParams.numBays &&
    newGenerationParams.numShelves === lassieGroup.generationParams.numShelves;

  return (
    <>
      <PropertiesLabel i18nkey='interface:properties.template.aisles'>
        <TemplateNumberInput value={newGenerationParams.numAisles} onChange={onNumAislesChange} />
      </PropertiesLabel>
      <PropertiesLabel i18nkey='interface:properties.template.bays'>
        <TemplateNumberInput value={newGenerationParams.numBays} onChange={onNumBaysChange} />
      </PropertiesLabel>
      <PropertiesLabel i18nkey='interface:properties.template.shelves'>
        <TemplateNumberInput value={newGenerationParams.numShelves} onChange={onNumShelvesChange} />
      </PropertiesLabel>
      <Button disabled={regenerationDisabled} onClick={regenerate}>
        {t('common:apply')}
      </Button>
    </>
  );
};

export const TemplateProperty = memo(TemplatePropertyComponent);
