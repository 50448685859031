import { LoadPositionAdjustment, LocationAdjustment } from '@/modules/commissioning/helpers/types';
import { boundingBoxToLine } from '@/modules/common/helpers/boundingBox';
import { calculateShapesBoundingBox, isRoad } from '@/modules/common/helpers/shapes';
import { ShapeGroup } from '@/modules/common/types/shapeGroup';
import { StorageType } from '@/modules/common/types/storage';
import { createRackSpec } from '@/modules/floorplanService/helpers/mapping/createRackSpec';
import { linkCutOutsToEndPointGenerators } from '@/modules/floorplanService/helpers/mapping/linkCutOutsToEndPointGenerators';
import { UnifiedVehicle, UnifiedVehicleDetails, VehicleAssets } from '@/modules/vehicles';
import { AreaShape, PositionShape } from '@/store/recoil/shape';
import { Connection, Crossing, DistantConnection } from '@modules/common/types/connections';
import { LoadCarrierType } from '@modules/common/types/floorPlan';
import {
  isAngledHighwayShape,
  isAngledHighwayShapePersisted,
  isAreaShape,
  isHighwayShape,
  isHighwayShapePersisted,
  isObstacleShape,
  isPositionShape,
  isWallShape,
} from '@modules/common/types/guards';
import { DTShape, ShapeType } from '@modules/common/types/shapes';
import { GenerateFloorPlanExportSettings, IdGenerator } from '@modules/floorplanService';
import { ConnectionMapping } from './connectionMapping';
import { createAllLoadPositionGroupGenerationSettings } from './createAllLoadPositionGroupGenerationSettings';
import { createAngledHighway, interlinkAngledHighway } from './createAngledHighway';
import { createHighway } from './createHighway';
import { createArea, createObstacle, createWall } from './createShape';
import { mapHighwayCrossings } from './crossing/mapHighwayCrossings';
import { DistantConnectionMapping } from './distantConnectionMapping';
import { genTStackExportProperties } from './genTStackExportProperties';
import {
  decodeShapeId,
  encodeIdWithSegmentIndex,
  encodeIdWithVehicleId,
  getSegmentIndex,
} from './idEncoder';
import { RackSpec } from './types';
import { findConnections, findCrossings, getAngledHighwaySegment, reflectorsToFpsReflectors } from './utils';
import { vehiclesToVehicleSpecs } from './vehiclesToVehicleSpecs';

export const mapStateToPayload = (
  name: string,
  version: string,
  shapes: DTShape[],
  groups: ShapeGroup[],
  loadTypes: LoadCarrierType[],
  vehicles: UnifiedVehicle[],
  vehicleDetails: UnifiedVehicleDetails[],
  vehicleAssets: VehicleAssets[],
  connections: Connection[],
  crossings: Crossing[],
  distantConnections: DistantConnection[],
  exportSettings: GenerateFloorPlanExportSettings,
  locationAdjustments: LocationAdjustment[],
  loadPositionAdjustments: LoadPositionAdjustment[],
  fileUrl: string,
  customIdGeneratorMap: Map<string, IdGenerator>,
) => {
  const rackSpecs: RackSpec[] = [];
  const obstacles = [];
  const idGenerators = [];
  const checkPointIdGenerators = [];
  const fixedEndPointGenerationSettings = [];
  const workspaceBoundingBox = calculateShapesBoundingBox(shapes);
  const cutOutReferences = [];
  const loadPositionGroupGenerationSettings = [];
  const tStackSpecificProperties = genTStackExportProperties(
    exportSettings.tStackExport,
    vehicles,
    vehicleAssets,
    fileUrl,
  );
  let vehicleSpecs = vehiclesToVehicleSpecs(vehicles, vehicleDetails, vehicleAssets, fileUrl);

  const shapeWalls = shapes.filter(isWallShape);
  const shapeObstacles = shapes.filter(isObstacleShape);
  const shapeHighways = shapes.filter(isHighwayShape);
  const angledHighways = shapes.filter(isAngledHighwayShape);
  const shapeAreas = shapes.filter(
    (shape): shape is AreaShape | PositionShape => isAreaShape(shape) || isPositionShape(shape),
  );

  shapeWalls.forEach((shape) => {
    createWall(obstacles, cutOutReferences, shape, workspaceBoundingBox, 'WALL');
  });

  shapeObstacles.forEach((shape) => {
    createObstacle(obstacles, cutOutReferences, shape, workspaceBoundingBox, 'PILLAR');
  });

  // NOTE: creating a rack spec for each racking area right now.
  // TODO rack: replace by reusable racks (rack templates referencable by each shape)
  shapeAreas
    .filter(isAreaShape)
    .filter(
      (shape) =>
        shape.parameters.storageType === StorageType.RACK ||
        shape.parameters.storageType === StorageType.TWOSIDEDRACK,
    )
    .forEach((shape) => {
      createRackSpec(rackSpecs, shape, loadTypes);
    });

  shapeHighways.forEach((shape) => {
    vehicleSpecs
      .filter(
        (vehicleSpec) =>
          !shape.parameters.supportedVehicleIds ||
          shape.parameters.supportedVehicleIds.length === 0 ||
          shape.parameters.supportedVehicleIds.includes(vehicleSpec.vehicleSpecId),
      )
      .forEach((vehicleSpec) => {
        createHighway(
          vehicleSpec,
          checkPointIdGenerators,
          shape.name,
          shape.id,
          'highway',
          shape.parameters.laneDirection,
          shape.parameters.margin,
          shape.parameters.gap,
          shape.parameters.routingPointGroupMinGap,
          shape.parameters.routingPointMarginToCrossing,
          shape.parameters.checkPointIdGeneratorName,
          shape.properties,
          workspaceBoundingBox,
          customIdGeneratorMap,
        );
      });
  });

  shapeAreas.forEach((shape) => {
    vehicleSpecs
      .filter((vehicleSpec) =>
        shape.parameters.supportedVehicleIds.includes(vehicleSpec.vehicleSpecId),
      )
      .forEach((vehicleSpec) => {
        createArea(
          vehicleSpec,
          idGenerators,
          shape,
          { width: vehicleSpec.width, length: vehicleSpec.length },
          loadTypes,
          workspaceBoundingBox,
          customIdGeneratorMap,
        );
      });
  });

  angledHighways.forEach((shape) => {
    vehicleSpecs.forEach((vehicleSpec) => {
      createAngledHighway(
        vehicleSpec,
        checkPointIdGenerators,
        shape,
        workspaceBoundingBox,
        customIdGeneratorMap,
      );
    });
  });

  createAllLoadPositionGroupGenerationSettings(groups, shapes, loadPositionGroupGenerationSettings);
  vehicleSpecs.forEach((vehicleSpec) => {
    linkCutOutsToEndPointGenerators(vehicleSpec.endPointGenerationSettings, cutOutReferences);
  });

  vehicleSpecs
    .filter((vehicleSpec) => vehicleSpec.areas.some((area) => !isRoad(area.type)))
    .forEach((vehicleSpec) => {
      shapes
        .filter((shape) => shape.type === ShapeType.HIGHWAY_ANGLED)
        .forEach((angledHighway) => {
          interlinkAngledHighway(vehicleSpec, angledHighway.id);
        });
      vehicleSpec.checkPointGenerationSettings.forEach((cp) => {
        const shape = shapes.filter((shape) => {
          if (shape.type === ShapeType.HIGHWAY_ANGLED) {
            return shape.id === decodeShapeId(cp.areaReference.name);
          }
          if (shape.type === ShapeType.HIGHWAY) {
            return (
              encodeIdWithVehicleId(shape.id, vehicleSpec.databaseId) === cp.areaReference.name
            );
          }
          return null;
        })[0];

        if (!isHighwayShapePersisted(shape) && !isAngledHighwayShapePersisted(shape)) {
          console.warn("expected a shape with type 'highway'. Investigate");
          return;
        }

        const highwayId = encodeIdWithSegmentIndex(shape.id, cp.areaReference.name);
        const highwayMappingId = encodeIdWithVehicleId(highwayId, vehicleSpec.databaseId);
        const highwayLine = isAngledHighwayShapePersisted(shape)
          ? getAngledHighwaySegment(shape, getSegmentIndex(cp.areaReference.name).toString())
          : boundingBoxToLine(shape.properties);
        const highwayConnections = findConnections(highwayId, connections);
        const highwayDistcons = findConnections(highwayId, distantConnections);
        const highwayCrossings = findCrossings(highwayId, crossings);

        ConnectionMapping(
          vehicleSpec,
          highwayMappingId,
          highwayConnections,
          highwayLine.points,
          shape.parameters.laneDirection,
          connections,
          shape,
          shapes,
        );
        DistantConnectionMapping(
          vehicleSpec,
          highwayMappingId,
          highwayDistcons,
          highwayLine.points,
          shape.parameters.laneDirection,
          connections,
          shape,
          shapes,
        );
        mapHighwayCrossings(vehicleSpec, highwayMappingId, highwayCrossings, shapes);

        // LEGACY MAPPING TO BE OBSOLETED
        // addCrossingCutOutsToCheckPointGenerator(
        //   cp,
        //   highwayCrossings,
        //   highwayId,
        //   shape,
        //   shapeState,
        //   vehicleSpec,
        // );
        // snapCrossingsToEdges(
        //   vehicleSpec,
        //   highwayCrossings,
        //   shape,
        //   shapeState,
        //   workspaceBoundingBox,
        // );
      });
    });

  vehicleSpecs = vehicleSpecs
    .filter((vehicleSpec) => vehicleSpec.areas.some((area) => !isRoad(area.type)))
    .map((vehicleSpec) => ({
      ...vehicleSpec,
      endPointGenerationSettings: vehicleSpec.endPointGenerationSettings.map((obj) => obj.data),
      fixedEndPointGenerationSettings,
      locationGroups: [],
      locationAdjustments,
    }));

  return {
    svgExport: exportSettings.splines,
    unifiedFormatExport: exportSettings.unifiedLayout,
    collisionDbExport: exportSettings.collision,
    tStackExport: exportSettings.tStackExport,
    maestroExport: exportSettings.maestroExport,
    includeFloorPlanArtefactsInOutput: exportSettings.includeFloorPlanArtefactsInOutput,
    ...tStackSpecificProperties,
    definition: {
      name,
      version,
      unitOfTime: 'MS',
      unitOfLength: 'MM',
      unitOfVelocity: 'MM_PER_SECOND',
      unitOfOrientation: 'DEGREE',
      transformationDeltaX: exportSettings.floorPlanAdjustment.transformationDeltaX,
      transformationDeltaY: exportSettings.floorPlanAdjustment.transformationDeltaY,
      transformationAngle: exportSettings.floorPlanAdjustment.transformationAngle,
      idGenerators: [...idGenerators, ...checkPointIdGenerators],
      idShorteners: [],
      loadPositionGroupGenerationSettings,
      loadTypes,
      loadPositionAdjustments,
      numericIdGenerators: [
        {
          name: 'PointIdGen',
          startId: 1,
          increment: 1,
        },
        {
          name: 'ConnIdGen',
          startId: 1,
          increment: 1,
        },
      ],
      obstacles,
      rackSpecs,
      vehicleSpecs,
      reflectors: exportSettings.tStackExport
        ? reflectorsToFpsReflectors(exportSettings.reflectors)
        : [],
    },
  };
};
